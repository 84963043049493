import React, { useState, useEffect } from "react";
import { Mic, MicOff, Volume2 } from "lucide-react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function Voicebot() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

     
    const [isListening, setIsListening] = useState(false);
    const [spokenText, setSpokenText] = useState("");
    const [botResponse, setBotResponse] = useState("");
    const [recognition, setRecognition] = useState(null);
    const [isCreatingTask, setIsCreatingTask] = useState(false);
    const [isEditingTask, setIsEditingTask] = useState(false);
    const [currentStep, setCurrentStep] = useState("");
    const [editField, setEditField] = useState("");
    const [voiceBotpopup, setVoiceBotpopup] = useState(false)
    const [userData, setUserData] = useState([])
    const [selectedAssignee, setSelectedAssignee] = useState("");
  
    // leave
  
    const [currentFlow, setCurrentFlow] = useState("");
    const [leaveType, setLeaveType] = useState(" ");
    const [leaveDetails, setLeaveDetails] = useState({
      leaveType: "",
      isFullDay: null,
      fromDate: "",
      toDate: "",
      startTime: "",
      endTime: "",
      reason: "",
    });
  

    // 
    const [taskDetails, setTaskDetails] = useState({
      title: "",
      description: "",
      assigneeUserId: "",
      assigneeName: "",
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
      priority: "",
    });
  
  
    const token = localStorage.getItem('token')
    const userInfo = jwtDecode(token)
    const userId = userInfo?.userId
  
  
    const handleOpenPopup = () => {
      setVoiceBotpopup(true)
    }
  
    const handleClosePopup = () => {
      setVoiceBotpopup(false)
  
      setCurrentFlow("");
  
    }
  
  
    useEffect(() => {
      if ("webkitSpeechRecognition" in window) {
        const recognitionInstance = new window.webkitSpeechRecognition();
        recognitionInstance.continuous = false;
        recognitionInstance.interimResults = false;
        recognitionInstance.lang = "en-US";
  
        recognitionInstance.onresult = (event) => {
          const transcript = event.results[0][0].transcript;
          setSpokenText(transcript);
  
  
          if (currentFlow === "leave") {
            handleLeaveRequestStep(transcript.trim());
          } else if (isCreatingTask) {
            handleTaskCreationStep(transcript.trim());
          } else if (isEditingTask) {
            handleTaskEdit(transcript.trim());
          } else {
            handleInitialCommand(transcript.trim());
          }
  
          setIsListening(false);
        };
  
        recognitionInstance.onerror = (event) => {
          console.error("Speech recognition error:", event.error);
          setIsListening(false);
        };
  
        recognitionInstance.onend = () => {
          setIsListening(false);
        };
  
        setRecognition(recognitionInstance);
      }
  
      EmployeeDetails();
    }, [isCreatingTask, isEditingTask, currentStep, editField, currentFlow]);
  
    const parseDate = (spokenDate) => {
      const monthNames = [
        "january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
      ];
  
      const cleanedDate = spokenDate.trim().toLowerCase().replace(/\s+/g, " ");
      console.log("Cleaned date input:", cleanedDate);
  
      const parts = cleanedDate.split(/[\s-]+/);
  
      if (parts.length >= 2 && isNaN(parts[1])) {
        const day = parseInt(parts[0], 10);
        const monthIndex = monthNames.indexOf(parts[1]);
        if (monthIndex !== -1) {
          const month = monthIndex + 1;
          const year = parts[2] ? parseInt(parts[2], 10) : new Date().getFullYear();
  
          if (day > 0 && day <= 31) {
            return `${String(day).padStart(2, "0")}-${String(month).padStart(2, "0")}-${year}`;
          }
        }
      }
  
      if (parts.length >= 2) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const year = parts[2] ? parseInt(parts[2], 10) : new Date().getFullYear();
  
        if (day > 0 && day <= 31 && month > 0 && month <= 12) {
          return `${String(day).padStart(2, "0")}-${String(month).padStart(2, "0")}-${year}`;
        }
      }
  
      return null;
    };
  
  
    const combineDateTime = (dateStr, timeStr) => {
      if (!dateStr || !timeStr) return null;
  
      const [day, month, year] = dateStr.split('-');
  
      const [hours, minutes] = timeStr.split(':');
  
      const utcDate = new Date(Date.UTC(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hours) - 5,
        parseInt(minutes) - 30
      ));
  
      if (utcDate.getMinutes() < 0) {
        utcDate.setMinutes(utcDate.getMinutes() + 60);
        utcDate.setHours(utcDate.getHours() - 1);
      }
  
      if (utcDate.getHours() < 0) {
        utcDate.setHours(utcDate.getHours() + 24);
        utcDate.setDate(utcDate.getDate() - 1);
      }
  
      return utcDate.toISOString();
    };
  
    const handleInitialCommand = (command) => {
      const lowerCommand = command.toLowerCase();
      let response = "";
  
      if (lowerCommand.includes("edit task") || lowerCommand.includes("modify task")) {
        setIsEditingTask(true);
        response = `What would you like to edit? You can say:
          - Edit title
          - Edit description
          - Edit assignee
          - Edit start date
          - Edit end date
          - Edit start time
          - Edit end time
          - Edit priority.`;
      } else if (
        lowerCommand.includes("create task") ||
        lowerCommand.includes("task") ||
        lowerCommand.includes("create a task")
      ) {
        setIsCreatingTask(true);
        setCurrentStep("title");
        response = "Let's create a task. What is the title of the task?";
      } else if (
        lowerCommand.includes("leave request") ||
        lowerCommand.includes("sick leave") ||
        lowerCommand.includes("work from home")
      ) {
        let detectedLeaveType = "Casual";
        if (lowerCommand.includes("sick leave")) detectedLeaveType = "Sick";
        else if (lowerCommand.includes("work from home")) detectedLeaveType = "WFH";
  
        setLeaveType(detectedLeaveType);
        setCurrentFlow("leave");
        setBotResponse(`Starting your ${detectedLeaveType} leave request.`);
      } else {
        setBotResponse("Sorry, I didn't understand that. You can create tasks or request leaves.");
      }
    
  
      setBotResponse(response);
      speak(response);
    };
  

    function parseSpokenTime(timeStr) {
      const cleanedTime = timeStr
        .toLowerCase()
        .trim()
        .replace(/\./g, '')
        .replace(/\s+/g, '');
  
      const shortTimeRegex = /^(\d{1,2})(am|pm)$/;
      let match = cleanedTime.match(shortTimeRegex);
      if (match) {
        let hour = parseInt(match[1], 10);
        if (match[2] === 'pm' && hour !== 12) {
          hour += 12;
        } else if (match[2] === 'am' && hour === 12) {
          hour = 0;
        }
        return `${hour.toString().padStart(2, '0')}:00`;
      }
  
      const militaryTimeRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
      match = cleanedTime.match(militaryTimeRegex);
      if (match) {
        const hour = match[1].padStart(2, '0');
        const minutes = match[2];
        return `${hour}:${minutes}`;
      }
  
      const twelveHourRegex = /^(\d{1,2}):([0-5][0-9])(am|pm)$/;
      match = cleanedTime.match(twelveHourRegex);
      if (match) {
        let hour = parseInt(match[1], 10);
        const minutes = match[2];
  
        if (match[3] === 'pm' && hour !== 12) {
          hour += 12;
        } else if (match[3] === 'am' && hour === 12) {
          hour = 0;
        }
  
        return `${hour.toString().padStart(2, '0')}:${minutes}`;
      }
  
      return null;
    }
  
    function levenshteinDistance(a, b) {
      const matrix = Array.from({ length: a.length + 1 }, () =>
        Array(b.length + 1).fill(0)
      );
  
      for (let i = 0; i <= a.length; i++) {
        for (let j = 0; j <= b.length; j++) {
          if (i === 0) matrix[i][j] = j;
          else if (j === 0) matrix[i][j] = i;
          else {
            matrix[i][j] = Math.min(
              matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1),
              matrix[i - 1][j] + 1,
              matrix[i][j - 1] + 1
            );
          }
        }
      }
  
      return matrix[a.length][b.length];
    }
  
    function findClosestMatch(input, userData) {
      const normalizedInput = input.toLowerCase().replace(/\s+/g, '');
      let closest = null;
      let minDistance = Infinity;
  
      userData.forEach(emp => {
        const fullName = emp.empName.toLowerCase();
        const [firstName, lastName] = fullName.split(/\s+/);
  
        // Calculate distances for full name, first name, and last name
        const fullNameDistance = levenshteinDistance(normalizedInput, fullName.replace(/\s+/g, ''));
        const firstNameDistance = firstName ? levenshteinDistance(normalizedInput, firstName) : Infinity;
        const lastNameDistance = lastName ? levenshteinDistance(normalizedInput, lastName) : Infinity;
  
        // Choose the smallest distance
        const minEmpDistance = Math.min(fullNameDistance, firstNameDistance, lastNameDistance);
  
        if (minEmpDistance < minDistance) {
          minDistance = minEmpDistance;
          closest = emp;
        }
      });
  
      console.log("Matched Employee:", closest); // Debugging log
      return minDistance <= 2 ? closest : null; // Adjust threshold as needed
    }
  
  // task

    const handleTaskCreationStep = (command) => {
      let response = "";
      let nextStep = "";
    
      const updateTaskDetails = (value) => {
        setTaskDetails((prev) => ({
          ...prev,
          [currentStep]: value,
        }));
      };
    
      switch (currentStep) {
        case "title":
          updateTaskDetails(command);
          nextStep = "description";
          response = `Title set to "${command}". What is the description of the task?`;
          break;
    
        case "description":
          updateTaskDetails(command);
          nextStep = "assigneeTo";
          response = `Description "${command}" saved. Who do you want to assign this task to?`;
          break;
    
        case "assigneeTo":
          const matchedEmployee = selectedAssignee
            ? findClosestMatch(selectedAssignee.toLowerCase(), userData)
            : findClosestMatch(command.toLowerCase().trim(), userData);
    
          if (matchedEmployee) {
            console.log("Matched Employee Details:", matchedEmployee);
            setTaskDetails((prev) => ({
              ...prev,
              assigneeUserId: matchedEmployee.empUserId,
              assigneeName: matchedEmployee.empName,
            }));
            nextStep = "fromDate";
            response = `Task will be assigned to "${matchedEmployee.empName}". What is the start date for this task?`;
            setSelectedAssignee("");
          } else {
            nextStep = "assigneeTo";
            response = "Sorry, I couldn't find that employee. Please choose from the dropdown or say their name clearly.";
          }
          break;
    
        case "fromDate":
          const startDate = parseDate(command);
          if (startDate) {
            updateTaskDetails(startDate);
            nextStep = "toDate";
            response = `Start date set to ${startDate}. What is the end date for this task?`;
          } else {
            nextStep = "fromDate";
            response = "Sorry, I couldn't understand the date. Please say the date clearly.";
          }
          break;
    
        case "toDate":
          const endDate = parseDate(command);
          if (endDate) {
            updateTaskDetails(endDate);
            nextStep = "fromTime";
            response = `End date set to ${endDate}. What time should the task start?`;
          } else {
            nextStep = "toDate";
            response = "Sorry, I couldn't understand the date. Please say the date clearly.";
          }
          break;
    
        case "fromTime":
          const parsedStartTime = parseSpokenTime(command);
          if (parsedStartTime) {
            updateTaskDetails(parsedStartTime);
            nextStep = "toTime";
            response = `Start time set to ${parsedStartTime}. What time should the task end?`;
          } else {
            nextStep = "fromTime";
            response = "Sorry, I couldn't understand the time. Please say the time clearly.";
          }
          break;
    
        case "toTime":
          const parsedEndTime = parseSpokenTime(command);
          if (parsedEndTime) {
            updateTaskDetails(parsedEndTime);
            nextStep = "priority";
            response = `End time set to ${parsedEndTime}. What is the priority level? Say High, Medium, or Low.`;
          } else {
            nextStep = "toTime";
            response = "Sorry, I couldn't understand the time. Please say the time clearly.";
          }
          break;
    
        case "priority":
          const priority = command.toLowerCase().replace(/\./g, "");
          if (["high", "medium", "low"].includes(priority)) {
            updateTaskDetails(priority);
            nextStep = "confirmation";
            response = `Priority set to ${priority}. Here is the task summary:
              - Title: ${taskDetails.title}
              - Description: ${taskDetails.description}
              - Assigned to: ${taskDetails.assigneeName}
              - Dates: ${taskDetails.fromDate} to ${taskDetails.toDate}
              - Times: ${taskDetails.fromTime} to ${taskDetails.toTime}
              - Priority: ${priority}
              
              Say 'confirm' to submit the task or 'edit' to make changes.`;
          } else {
            nextStep = "priority";
            response = "Please specify a valid priority level: High, Medium, or Low.";
          }
          break;
    
        case "confirmation":
          if (command.toLowerCase().replace(/\./g, "") === "yes") {
            submitTaskDetails();
            response = "Task has been submitted successfully!";
            setIsCreatingTask(false);
            nextStep = "";
          } else if (command.toLowerCase().replace(/\./g, "") === "edit") {
            response = "What would you like to edit? Title, description, assignee, dates, times, or priority?";
            nextStep = ""; 
            setIsCreatingTask(false); 
            setIsEditingTask(true); 
          } else {
            response = "Please say 'yes' to submit the task or 'edit' to make changes.";
          }
          break;
    
        default:
          response = "An error occurred. Please try again.";
          setIsCreatingTask(false);
          nextStep = "";
      }
    
      setCurrentStep(nextStep);
      setBotResponse(response);
      speak(response);
    };
    
  
    const submitTaskDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const fromDateTime = combineDateTime(taskDetails.fromDate, taskDetails.fromTime);
        const toDateTime = combineDateTime(taskDetails.toDate, taskDetails.toTime);
  
  
  
        const response = await axios.post(
          `${baseUrl}/task/addTask`,
          {
            title: taskDetails.title,
            desc: taskDetails.description,
            assignUserId: taskDetails.assigneeUserId,
            assigneeName: taskDetails.assigneeName,
            from: fromDateTime,
            to: toDateTime,
            priority: taskDetails.priority,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        const successMessage = "Task has been successfully updated and saved!";
        console.log("API Response:", response.data);
        setBotResponse(successMessage);
        speak(successMessage);
      } catch (error) {
        console.error("Error submitting task details:", error);
        const errorMessage = "An error occurred while saving the task. Please try again.";
        setBotResponse(errorMessage);
        speak(errorMessage);
      }
    };
  
  
    const handleTaskEdit = (command) => {
      const lowerCommand = command.toLowerCase();
      let response = "";
  
      if (!editField) {
        if (lowerCommand.includes("title")) {
          setEditField("title");
          response = `Current title is "${taskDetails.title}". Please say the new title.`;
        } else if (lowerCommand.includes("description")) {
          setEditField("description");
          response = `Current description is "${taskDetails.description}". Please say the new description.`;
        } else if (lowerCommand.includes("assignee")) {
          setEditField("assigneeTo");
          response = `Current assignee is "${taskDetails.assigneeTo}". Please say the new assignee.`;
        } else if (lowerCommand.includes("start date")) {
          setEditField("fromDate");
          response = `Current start date is "${taskDetails.fromDate}". Please say the new start date in DD MM format.`;
        } else if (lowerCommand.includes("end date")) {
          setEditField("toDate");
          response = `Current end date is "${taskDetails.toDate}". Please say the new end date in DD MM format.`;
        } else if (lowerCommand.includes("start time")) {
          setEditField("fromTime");
          response = `Current start time is "${taskDetails.fromTime}". Please say the new time in 12-hour format with AM/PM.`;
        } else if (lowerCommand.includes("end time")) {
          setEditField("toTime");
          response = `Current end time is "${taskDetails.toTime}". Please say the new time in 12-hour format with AM/PM.`;
        } else if (lowerCommand.includes("priority")) {
          setEditField("priority");
          response = `Current priority is "${taskDetails.priority}". Please say the new priority (High, Medium, or Low).`;
        } else if (lowerCommand.includes("done") || lowerCommand.includes("finish")) {
          setIsEditingTask(false);
          submitTaskDetails(); // Call the API submission function
          response = "Editing completed. Task details have been submitted.";
        } else {
          response = "Please specify what you want to edit (title, description, assignee, dates, times, or priority).";
        }
      } else {
        let isValid = true;
        let newValue = command;
  
        if (editField === "fromDate" || editField === "toDate") {
          newValue = parseDate(command);
          isValid = newValue !== null;
        } else if (editField === "fromTime" || editField === "toTime") {
          isValid = parseSpokenTime(command) !== null;
          if (isValid) {
            newValue = command.toUpperCase();
          }
        } else if (editField === "priority") {
          isValid = ["highest", "medium", "lowest"].includes(lowerCommand);
          newValue = lowerCommand;
        }
  
        if (isValid) {
          setTaskDetails((prev) => ({
            ...prev,
            [editField]: newValue,
          }));
          response = `Updated ${editField} to "${newValue}". What else would you like to edit? Say "done" when finished.`;
          setEditField("");
        } else {
          response = "Invalid input. Please try again.";
        }
      }
  
      setBotResponse(response);
      speak(response);
    };
  
    const toggleListening = () => {
      if (isListening) {
        recognition?.stop();
      } else {
        recognition?.start();
      }
      setIsListening(!isListening);
    };
  
    const speak = (text) => {
      const utterance = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(utterance);
  
    };
  
    async function EmployeeDetails() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${baseUrl}/user/employeeDetails`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
  
        if (response) {
          setUserData(response?.data.result);
          console.log(response, "voice bot");
        } else {
          throw new Error("Failed to fetch employeedetails");
        }
      } catch (error) {
        console.error("Error fetching employeedetails:", error);
      }
    }

    // 
  
// leave
const handleLeaveRequestStep = (command) => {
  let response = "";
  let nextStep = currentStep; 

  console.log("User Input:", command); 
  console.log("Current Step Before:", currentStep); 

  switch (currentStep) {
    case "": 
      response = "Please provide the start date for your leave.";
      nextStep = "startDate";
      break;

    case "startDate": 
      const startDate = parseDate(command);
      if (startDate) {
        setLeaveDetails((prev) => ({ ...prev, fromDate: startDate }));
        response = "Start date saved. Now, please provide the end date for your leave.";
        nextStep = "endDate";
      } else {
        response = "Sorry, I couldn't understand the date. Please say the start date in DD-MM-YYYY format.";
      }
      break;

    case "endDate": 
      const endDate = parseDate(command);
      if (endDate) {
        setLeaveDetails((prev) => ({ ...prev, toDate: endDate }));
        response = "End date saved. Is this a short leave? Say 'yes' for short leave or 'no' for full day leave.";
        nextStep = "shortLeave";
      } else {
        response = "Sorry, I couldn't understand the date. Please say the end date in DD-MM-YYYY format.";
      }
      break;

    case "shortLeave": 

      if ( command.toLowerCase().replace(/\./g, '') === "yes") {
        setLeaveDetails((prev) => ({ ...prev, isFullDay: false })); 
        response = "Short leave selected. Please provide the start time for your short leave.";
        nextStep = "startTime";
      } else if ( command.toLowerCase().replace(/\./g, '') === "no") {
        setLeaveDetails((prev) => ({ ...prev, isFullDay: true })); 
        response = "Full-day leave selected. Please provide the reason for your leave.";
        nextStep = "reason";
      } else {
        response = "Please say 'yes' for short leave or 'no' for full day leave.";
      }
      break;

    case "startTime": 
      const startTime = parseSpokenTime(command);
      if (startTime) {
        setLeaveDetails((prev) => ({ ...prev, startTime }));
        response = "Start time saved. Please provide the end time for your short leave.";
        nextStep = "endTime";
      } else {
        response = "I couldn't understand the time. Please say it in HH:MM AM/PM format.";
      }
      break;

    case "endTime": 
      const endTime = parseSpokenTime(command);
      if (endTime) {
        setLeaveDetails((prev) => ({ ...prev, endTime }));
        response = "End time saved. Please provide the reason for your leave.";
        nextStep = "reason";
      } else {
        response = "I couldn't understand the time. Please say it in HH:MM AM/PM format.";
      }
      break;

    case "reason": 
      setLeaveDetails((prev) => ({ ...prev, reason: command }));
      response = "Reason noted. Say 'done' to submit your leave request or 'edit' to make changes.";
      nextStep = "confirmation";
      break;

    case "confirmation": 
      if ( command.toLowerCase().replace(/\./g, '') === "done") {
        submitLeaveRequest();
        response = "Your leave request has been submitted.";
        setCurrentFlow(""); 
        nextStep = ""; 
      } else if ( command.toLowerCase().replace(/\./g, '') === "edit") {
        response = "What would you like to edit? Start date, end date, start time, end time, or reason?";
        nextStep = ""; 
      } else {
        response = "Please say 'done' to submit your leave request or 'edit' to make changes.";
      }
      break;

    default:
      response = "An error occurred. Please try again.";
  }

  console.log("Next Step After:", nextStep); 
  setCurrentStep(nextStep);
  setBotResponse(response);
  speak(response);
};





  const submitLeaveRequest = async () => {
    try {
      const token = localStorage.getItem("token");
    
      const fromDateTime = combineDateTime(
        leaveDetails.fromDate,
        leaveDetails.startTime || new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      );
      const toDateTime = combineDateTime(
        leaveDetails.toDate,
        leaveDetails.endTime || new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      );
      

      const response = await axios.post(
        `${baseUrl}/smartservice/add`,
        {
          type: leaveType,
          from: leaveDetails.fromDate,
          to: leaveDetails.toDate,
          reason: leaveDetails.reason,
          isFullDay: leaveDetails.isFullDay,
          startTime:fromDateTime,
          endTime: toDateTime,


         
          
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response && response.data) {
        console.log("Leave submitted successfully:", response.data);
      }
    } catch (error) {
      console.error("Error submitting leave:", error);
    }
  };

  return (
 


<div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-lg">
<div className="text-center space-y-4">
  <h1 className="text-2xl font-bold">Voice Assistant</h1>
  <button onClick={handleOpenPopup}>
    <Mic />
  </button>
  {voiceBotpopup && (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-end z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl w-96 h-[90vh] mr-10">
          <div className="flex relative items-center justify-center">
            <button
              onClick={toggleListening}
              className={`p-4 rounded-full ${isListening ? "bg-red-500" : "bg-blue-500"
                } text-white hover:opacity-90 transition-opacity`}
            >
              {isListening ? <MicOff className="w-6 h-6" /> : <Mic className="w-6 h-6" />}
            </button>
            <button onClick={handleClosePopup} className="absolute right-0">
              X
            </button>
          </div>
          <div className="mt-4">
            {isListening && <div className="text-blue-500 font-medium">Listening...</div>}
            {spokenText && (
              <div className="mt-2">
                <h2 className="font-semibold">You said:</h2>
                <p className="text-gray-600">{spokenText}</p>
              </div>
            )}
            {botResponse && (
              <div className="mt-4">
                <h2 className="font-semibold flex items-center gap-2">
                  <Volume2 className="w-4 h-4" />
                  Response:
                </h2>
                <p className="text-gray-600 whitespace-pre-line">{botResponse}</p>
              </div>
            )}
            
          </div>
        </div>
      </div>
    </>
  )}
</div>
</div>
  );
}

export default Voicebot;
