import React, { useEffect, useState } from "react";
import { useTheme } from "../components/Theme/Themecontext";
import img from "../images/user/user-02.png";
import { FaQuestion } from "react-icons/fa";
import { Link, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";

function MeetingCard({ meetingInfos, key, refresh }) {
  const { t, i18n } = useTranslation();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const { styles } = useTheme();
  const { component, bluetext, border, bgTitle } = styles;

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [meetingInfo, setMeetingInfo] = useState(meetingInfos);

  useEffect(() => {
    setMeetingInfo(meetingInfos);
  }, [meetingInfos, successMessage]);

  //Get format DD MM YYYY
  const formatMonthDateYear = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  //Get time format
  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = hours + ":" + minutes + " " + ampm;
    return formattedTime;
  };

  // Get Initials function
  const getInitials = (name) => {
    const words = name.split(" ");

    if (words.length === 1) {
      return name.substring(0, 2).toUpperCase();
    } else {
      return words.map((word) => word[0].toUpperCase()).join("");
    }
  };

  const isParticipationAllowed = (startTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const tenMinutesBeforeStart = new Date(start.getTime() - 10 * 60 * 1000);

    return now < tenMinutesBeforeStart; // Return true if it's before 10 minutes before the meeting
  };

  const handleParticipation = (e, value, Id) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const userInfo = jwtDecode(token);
    console.log("User Info:", userInfo);

    const formData = {
      meetingId: Id,
      participation: value,
    };
    // console.log("Form Data:", formData);
    axios
      .post(`${baseUrl}/meeting/meetingParticipation`, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Response:", response.data);
        setSuccessMessage("Successfully Upated");
        setMeetingInfo(meetingInfos);
        refresh();
        setTimeout(() => {
          setSuccessMessage("");
          //   Navigate("/home/meeting");
        }, 1000);
      })
      .catch((error) => {
        console.error("Error adding asset:", error);
        setErrorMessage("Error Updateing the Status");

        setTimeout(() => {
          setErrorMessage("");
        }, 1000);
      });
  };

  return (
    <div>
      <section>
        <Link
          key={key}
          to={`/home/meeting/details?meetingId=${meetingInfo.meetingId}`}
        >
          <div
            style={component}
            className="w-[280px] min-[375px]:w-[340px] min-[425px]:w-[360px] sm:w-[350px] xl:w-[350px] h-[295px] border rounded-lg"
          >
            <p
              style={bgTitle}
              className={`text-[14px] px-2 rounded-t-[5px] ${
                i18n.language === "ar" ? "text-right" : "text-left"
              } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {t("mymeeting")}
            </p>
            <div style={bluetext} className="p-1 !text-[14px]">
              <div
                className={`flex gap-5 ${
                  i18n.language === "ar" ? "text-right" : "text-left"
                } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <h1 className=" text-[14px]">
                  {t("meetingName")} :{" "}
                  <span className="font-normal">{meetingInfo.title}</span>
                </h1>
              </div>
              <div
                className={`flex flex-wrap gap-1 text-[14px] break-all ${
                  i18n.language === "ar" ? "text-right" : "text-left"
                } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <p className="flex flex-row">{t("meetingdatetime")} :</p>
                <h3>
                  {formatMonthDateYear(meetingInfo.from)}{" "}
                  {formatTime(meetingInfo.from)} - {formatTime(meetingInfo.to)}
                </h3>
              </div>

              <div
                className={`flex flex-1 justify-between text-[14px] ${
                  i18n.language === "ar" ? "text-right" : "text-left"
                } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <div className="flex flex-row gap-2">
                  <p className="">{t("meetingloc")} :</p>
                  <h3>{meetingInfo.location}</h3>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center mt-1 mx-5">
                <div className="flex flex-col gap-1">
                  <p className="text-[14px] ">{t("meetingorg")} :</p>
                  <div className="w-full h-[24px] border bg-[#666666] text-[#f8f6ff] p-1 rounded-xl">
                    <div className="flex flex-row gap-2 mx-1">
                      <img
                        src={meetingInfo.userimage}
                        alt=""
                        className="w-[14px] h-[14px] rounded-full"
                      />

                      <p className="text-[10px] ">
                        {meetingInfo.username}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="text-[14px]">{t("momcreator")} :</p>
                  <div className="w-full h-[24px] border bg-[#666666] text-[#f8f6ff] p-1 rounded-xl">
                    <div className="flex flex-row gap-2 mx-1">
                      <img
                        src={meetingInfo.notes[0].userImage}
                        alt=""
                        className="w-[14px] h-[14px] rounded-full "
                      />
                      <p className="text-[10px] font-medium ">
                        {meetingInfo.notes[0].userName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-2  ">
                <div className="w-[159px] h-[25px] border rounded-[20px] p-1 bg-[#1e3354]">
                  <a
                    href={meetingInfo.link}
                    className="text-[10px] flex flex-1 justify-center gap-1 mx-1 text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span className="material-symbols-outlined !text-[16px] font-thin">
                      video_call
                    </span>
                    Join Meeting
                  </a>
                </div>
              </div>
            </div>

            <div className="p-2 z-20">
              <div className="relative flex flex-wrap gap-2 justify-center">
                {isParticipationAllowed(meetingInfo.from) && (
                  <>
                    <div
                      className="h-[24px] p-1 cursor-pointer"
                      onClick={(e) =>
                        handleParticipation(e, 1, meetingInfo.meetingId)
                      }
                    >
                      <div className="flex flex-1 justify-between mx-1 gap-2">
                        <div className="mt-[-4px] ">
                          <svg
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4615 18H4.423V6.99999L10.6923 0.807739L11.1345 1.24999C11.2257 1.34099 11.3023 1.45766 11.3645 1.59999C11.4267 1.74232 11.4578 1.87307 11.4578 1.99224V2.14999L10.4345 6.99999H17.3845C17.8025 6.99999 18.1763 7.16474 18.5058 7.49424C18.8353 7.82374 19 8.19749 19 8.61549V9.84624C19 9.93724 18.9898 10.0366 18.9693 10.1442C18.9488 10.2519 18.9218 10.3512 18.8885 10.4422L16.1578 16.9155C16.0206 17.2232 15.7898 17.4808 15.4655 17.6885C15.141 17.8962 14.8063 18 14.4615 18ZM5.423 17H14.4615C14.6025 17 14.7468 16.9615 14.8943 16.8845C15.0418 16.8077 15.1539 16.6795 15.2308 16.5L18 9.99999V8.61549C18 8.43599 17.9423 8.28849 17.827 8.17299C17.7115 8.05766 17.564 7.99999 17.3845 7.99999H9.19225L10.35 2.53849L5.423 7.42699V17ZM4.423 6.99999V7.99999H1V17H4.423V18H0V6.99999H4.423Z"
                              fill="#06580D"
                            />
                          </svg>
                        </div>{" "}
                      </div>
                    </div>

                    <div
                      className="h-[24px] p-1 cursor-pointer"
                      onClick={(e) =>
                        handleParticipation(e, 3, meetingInfo.meetingId)
                      }
                    >
                      <div className="flex flex-1 justify-between mx-1 gap-2">
                        <span class="material-symbols-outlined !text-[18px] text-red-600">
                          block
                        </span>{" "}
                      </div>
                    </div>

                    <div
                      className="h-[24px] p-1 cursor-pointer"
                      onClick={(e) =>
                        handleParticipation(e, 2, meetingInfo.meetingId)
                      }
                    >
                      <div className="flex flex-1 justify-between mx-1 gap-2">
                        <div className="mt-[-4px] ">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3880_12233"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3880_12233)">
                              <path
                                d="M6.5385 4.80775H16.577V15.8078L10.3077 22L9.8655 21.5578C9.77433 21.4668 9.69767 21.3501 9.6355 21.2078C9.57333 21.0654 9.54225 20.9347 9.54225 20.8155V20.6578L10.5655 15.8078H3.6155C3.1975 15.8078 2.82375 15.643 2.49425 15.3135C2.16475 14.984 2 14.6103 2 14.1923V12.9615C2 12.8705 2.01025 12.7712 2.03075 12.6635C2.05125 12.5558 2.07817 12.4565 2.1115 12.3655L4.84225 5.89225C4.97942 5.58459 5.21017 5.32692 5.5345 5.11926C5.859 4.91159 6.19367 4.80775 6.5385 4.80775ZM15.577 5.80775H6.5385C6.3975 5.80775 6.25325 5.84617 6.10575 5.923C5.95825 6 5.84608 6.12825 5.76925 6.30775L3 12.8078V14.1923C3 14.3718 3.05767 14.5192 3.173 14.6345C3.2885 14.75 3.436 14.8078 3.6155 14.8078H11.8077L10.65 20.2693L15.577 15.3808V5.80775ZM16.577 15.8078V14.8078H20V5.80775H16.577V4.80775H21V15.8078H16.577Z"
                                fill="#FF6F07"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="flex justify-between">
                {/* Accepted Section */}
                <div>
                  <div className="flex gap-1 items-center">
                    <p>Accepted</p>
                    <div>
                      <p className="h-[15px] w-[15px] rounded-full bg-[#1e3354] text-[8px] flex items-center justify-center text-[#f8f6ff]">
                        {
                          meetingInfo.attendees?.filter(
                            (info) => info.attendeesStatus === 1
                          ).length
                        }
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-0 justify-center">
                    {meetingInfo.attendees?.filter(
                      (info) => info.attendeesStatus === 1
                    ).length > 0 ? (
                      meetingInfo.attendees
                        .filter((info) => info.attendeesStatus === 1)
                        .slice(0, 4)
                        .map((profile, index) => (
                          <img
                            key={index}
                            src={profile.userImage}
                            alt=""
                            className="h-[20px] w-[20px] lg:h-[20px] border lg:w-[20px] rounded-full"
                          />
                        ))
                    ) : (
                      <p className="text-gray-500">N/A</p>
                    )}
                  </div>
                </div>

                {/* Maybe Section */}
                <div>
                  <div className="flex gap-1 items-center">
                    <p>Maybe</p>
                    <div>
                      <p className="h-[15px] w-[15px] rounded-full bg-[#1e3354] text-[8px] flex items-center justify-center text-[#f8f6ff]">
                        {
                          meetingInfo.attendees?.filter(
                            (info) => info.attendeesStatus === 3
                          ).length
                        }
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-0 justify-center">
                    {meetingInfo.attendees?.filter(
                      (info) => info.attendeesStatus === 3
                    ).length > 0 ? (
                      meetingInfo.attendees
                        .filter((info) => info.attendeesStatus === 3)
                        .slice(0, 4)
                        .map((profile, index) => (
                          <img
                            key={index}
                            src={profile.userImage}
                            alt=""
                            className="h-[20px] w-[20px] lg:h-[20px] border lg:w-[20px] rounded-full"
                          />
                        ))
                    ) : (
                      <p className="text-gray-500">N/A</p>
                    )}
                  </div>
                </div>

                {/* Pending Section */}
                <div>
                  <div className="flex gap-1 items-center">
                    <p>Pending</p>
                    <div>
                      <p className="h-[15px] w-[15px] rounded-full bg-[#1e3354] text-[8px] flex items-center justify-center text-[#f8f6ff]">
                        {
                          meetingInfo.attendees?.filter(
                            (info) => info.attendeesStatus === 2
                          ).length
                        }
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-0 justify-center">
                    {meetingInfo.attendees?.filter(
                      (info) => info.attendeesStatus === 2
                    ).length > 0 ? (
                      meetingInfo.attendees
                        .filter((info) => info.attendeesStatus === 2)
                        .slice(0, 4)
                        .map((profile, index) => (
                          <img
                            key={index}
                            src={profile.userImage}
                            alt=""
                            className="h-[20px] w-[20px] lg:h-[20px] border lg:w-[20px] rounded-full"
                          />
                        ))
                    ) : (
                      <p className="">N/A</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </section>
    </div>
  );
}

export default MeetingCard;
