import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "./Theme/Themecontext";
import user from "../images/user/user-02.png";
import { useTranslation } from "react-i18next";

function RecentTask({ reloadCounter }) {

  const { t, i18n } = useTranslation();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [taskInfo, setTaskInfo] = useState([]);
  const [daysLeft, setDaysLeft] = useState([]);
  const { styles } = useTheme();
  const { component, bgTitle, bluetext, border } = styles;

  const fetchUserclkInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/home/todayTask`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTaskInfo(data.result);
        console.log(data.result)
      } else {
        throw new Error("Failed to fetch booking details");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const calculateDaysLeft = (toDates) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(currentDate.getDate() + 1);

    const daysLeftArray = toDates.map((toDate) => {
      const endTime = new Date(toDate);
      const differenceInTime = endTime.getTime() - currentDate.getTime();
      return Math.ceil(differenceInTime / (1000 * 3600 * 24));
    });
    return daysLeftArray;
  };

  useEffect(() => {
    fetchUserclkInfo();
  }, []);

  useEffect(() => {
    const toDates = taskInfo.map((task) => task.to);
    setDaysLeft(calculateDaysLeft(toDates));
  }, [taskInfo]);

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  // const getInitials = (name) => {
  //   const words = name.split(" ");
  //   return words.map((word) => word[0].toUpperCase()).join("");
  // };

  const formatMonthDateYear = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const formatDay = (dateString) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  };
  // const truncateText = (text, maxWords) => {
  //   const words = text.split(" ");
  //   if (words.length > maxWords) {
  //     return words.slice(0, maxWords).join(" ") + "...";
  //   }
  //   return text;
  // };


  const truncateWords = (str, numWords) => {
    const words = str.split(" ");
    return words.length > numWords
      ? words.slice(0, numWords).join(" ") + "..."
      : str;
  };

  const getInitials = (name) => {
    if (!name || typeof name !== "string") return "";

    const words = name.trim().split(" ");

    if (words.length === 1) {
      return words[0].substring(0, 2).toUpperCase();
    } else {
      return words
        .slice(0, 2)
        .map((word) => word[0].toUpperCase())
        .join("");
    }
  };

  const RemainingDays = (startDate, endDate) => {
    let remainingDays;
    const start = startDate ? new Date(startDate) : new Date();
    const end = new Date(endDate);
    const curDate = new Date();
    const diffInMilliseconds = end - (startDate === null ? curDate : start);
    remainingDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
    return remainingDays;
  };
  return (
    <>
      <div
        style={window.innerWidth < 768 ? component : {}}
        className={`!border-hidden md:p-0 md:bg-transparent w-auto ${i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}
      >
        {/* <div style={bluetext} className={`mb-2 md:hidden flex justify-between ${i18n.language === 'ar' ? 'flex-row-reverse justify-end' : 'flex-row justify-start'}`}>
          <p className=" text-[16px] font-[600]">{t('allmytasks')}</p> */}
          {/* <Link to="/home/task">
            <p className=" text-[16px] cursor-pointer">View all</p>
          </Link> */}
        {/* </div> */}
        {taskInfo.length === 0 ? (
          <p className="text-[14px] text-center">{t('notask')}</p>
        ) : (
          <div className="flex justify-between ">
            <div className="w-full grid grid-flow-col !md:grid-cols-2 lg:grid-cols-3 scroll-container gap-2 lg:gap-5 !xl:gap-10">
              {taskInfo.slice(0, 3).map((data, index) => (
                <Link
                  to={`/home/task/taskdetail?taskId=${data.taskId}`}
                  key={data.taskId}
                >
                  <div
                    style={component}
                    className="snap-center flex-shrink-0 border-[2px] flex relative border-opacity-[50%] w-[280px] min-[375px]:w-[340px] min-[425px]:w-[360px] sm:w-[350px] h-[230px] xl:w-[350px] md:h-full bg-[#FFFFFF] rounded-[10px] "
                  >
                    <div style={bluetext} className={`min-w-[230px] w-full ${i18n.language === 'ar' ? 'text-right' : 'text-left'} ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                      <p style={bgTitle} className="text-[14px] px-2 rounded-t-[5px]">
                        {t('mytaskcard')}
                      </p>
                      <div className="p-2 text-[14px]">
                        <div  className="flex justify-between ">
                          <div className="">
                            <p className="text-[14px] font-[500] ">
                              {t('taskname')}:
                              <span> {truncateWords(data.title, 3)}</span>
                            </p>
                            {data.projectName && (
                              <p className="text-[14px] font-[500]">
                                {t('projectname')}:{" "}
                                <span className="font-normal">
                                  {data.projectName}
                                </span>
                              </p>
                            )}

                          </div>

                          <div>
                            <p className="text-[14px]">{t('duedate')}:</p>
                            <p className="text-[14px]">
                              {" "}
                              {formatMonthDateYear(data.to)}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-row justify-between items-center mt-2 mx-5">
                          <div className="flex flex-col gap-1">
                            <p className="text-[14px] ">{t("assignedBy")}:</p>
                            <div className="w-full h-[24px] border bg-[#666666] text-[#f8f6ff] p-1 rounded-xl">
                              <div className="flex flex-row gap-2 mx-1">
                                <img
                                  src={data.assignUserImage}
                                  alt=""
                                  className="w-[14px] h-[14px] rounded-full "
                                />
                                <p className="text-[10px] ">
                                  {data.assignUserName}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-center gap-1 ">
                            <p className="text-[14px]">{t("acceptedby")} :</p>
                            <div className="w-full h-[24px] border bg-[#666666] text-[#f8f6ff] p-1 rounded-xl">
                              <div className="flex flex-row gap-2 mx-1">
                                <img
                                  src={data.userImage}
                                  alt=""
                                  className="w-[14px] h-[14px] rounded-full "
                                />
                                <p className="text-[10px] font-medium ">
                                  {data.userName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between  mt-2">
                          <div className="flex">
                            <div className="self-end flex lg:gap-1 gap-3 xl:gap-3">
                              <div>
                                <p>Priority</p>
                                <div
                                  className={`w-[50px] lg:w-[40px] xl:w-[50px] h-[20px] flex items-center justify-center rounded-[20px] ${data.priority === 2 || data.priority === "2"
                                      ? "bg-[#EA001B]"
                                      : data.priority === 1 ||
                                        data.priority === "1"
                                        ? "bg-[#F83600]"
                                        : "bg-[#1E3354]"
                                    }`}
                                >
                                  <p className="text-[#F8F6FF] text-[10px]">
                                    {data.priority === 2 ||
                                      data.priority === "2"
                                      ? "High"
                                      : data.priority === 1 ||
                                        data.priority === "1"
                                        ? "Medium"
                                        : "Low"}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <p>Status</p>
                                <div
                                  className={`w-auto h-[20px] px-2 py-2 flex items-center justify-center rounded-[20px] ${data.status === "Pending"
                                      ? "bg-[#EA001B]"
                                      : data.status === "In Progress"
                                        ? "bg-[#06580D]"
                                        : data.status === "Completed"
                                          ? "bg-green-500"
                                          : ""
                                    }`}
                                >
                                  <p className="text-[#F8F6FF] text-[8px] md:text-[10px]">
                                    {data.status === "Pending"
                                      ? "Initiated"
                                      : data.status === "In Progress"
                                        ? "In Progress"
                                        : data.status === "Completed"
                                          ? "Completed"
                                          : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {data.status !== "Completed" && (
                            <div
                              className={`flex mt-4 w-[90px] items-center justify-center p-2 ${RemainingDays(null, data.to) <= 0 ? 'bg-[#EA001B]' : 'bg-[#06580D]'
                                }`}
                            >
                              <p
                                className={`font-semi-bold text-[8px] md:text-[10px] ${RemainingDays(null, data.to) <= 0 ? 'text-white' : 'text-[#F8F6FF]'
                                  }`}
                              >
                                {RemainingDays(null, data.to) <= 0 ? 'Delayed' : `${RemainingDays(null, data.to)} Days Left`}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div className="flex mt-2">
                        <p className="text-[#787878] text-[14px] truncate-1-lines">
                          {data.desc}
                        </p>
                      </div>
                      <div className=" flex justify-between">
                        <div className="flex absolute bottom-3">
                          <div className="w-7">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_464_3720"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="18"
                                height="18"
                              >
                                <rect width="18" height="18" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_464_3720)">
                                <path
                                  d="M3.75 6H14.25V4.5H3.75V6ZM3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39688 15.7656 2.25 15.4125 2.25 15V4.5C2.25 4.0875 2.39688 3.73438 2.69063 3.44063C2.98438 3.14688 3.3375 3 3.75 3H4.5V1.5H6V3H12V1.5H13.5V3H14.25C14.6625 3 15.0156 3.14688 15.3094 3.44063C15.6031 3.73438 15.75 4.0875 15.75 4.5V8.75625C15.5125 8.64375 15.2688 8.55 15.0187 8.475C14.7687 8.4 14.5125 8.34375 14.25 8.30625V7.5H3.75V15H8.475C8.5625 15.275 8.66563 15.5375 8.78438 15.7875C8.90313 16.0375 9.04375 16.275 9.20625 16.5H3.75ZM13.5 17.25C12.4625 17.25 11.5781 16.8844 10.8469 16.1531C10.1156 15.4219 9.75 14.5375 9.75 13.5C9.75 12.4625 10.1156 11.5781 10.8469 10.8469C11.5781 10.1156 12.4625 9.75 13.5 9.75C14.5375 9.75 15.4219 10.1156 16.1531 10.8469C16.8844 11.5781 17.25 12.4625 17.25 13.5C17.25 14.5375 16.8844 15.4219 16.1531 16.1531C15.4219 16.8844 14.5375 17.25 13.5 17.25ZM14.7563 15.2812L15.2812 14.7563L13.875 13.35V11.25H13.125V13.65L14.7563 15.2812Z"
                                  fill="#DC3545"
                                />
                              </g>
                            </svg>
                          </div>
                          <p className="text-[#787878] text-[10px]">
                            {formatDay(data.to)}, {formatMonthDateYear(data.to)}
                          </p>
                        </div>
                        <div className="flex absolute bottom-3 right-2">
                          <div className="flex gap-1">
                            <div
                              className={`w-[50px] h-[20px] flex items-center justify-center rounded-[20px] ${
                                data.priority === 2
                                  ? "bg-red-500"
                                  : data.priority === 1
                                  ? "bg-orange-500"
                                  : "bg-green-500"
                              } text-[#ffff]`}
                            >
                              <p className="text-[10px]">
                                {data.priority === 2
                                  ? "High"
                                  : data.priority === 1
                                  ? "Medium"
                                  : "Low"}
                              </p>
                            </div>
                            <div className="w-[60px] h-[20px] font-bold bg-[#68db86] flex items-center justify-center rounded-[20px]">
                              <p className="text-[#F8F6FF] text-[10px]">
                                {data.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RecentTask;
