import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../components/Theme/Themecontext"
import { useTranslation } from "react-i18next";

function Footer() {

  const { t, i18n } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { styles } = useTheme();
  const { bluetext } = styles
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuItems = [
    {
      link: "../home/smartservicedashboard",
      icon: (
        <span class="material-symbols-outlined">
          hourglass_empty
        </span>
      ),
    },
    {
      link: "../home/task",
      icon: (
        <span
          className="material-symbols-outlined span">
          list_alt
        </span>
      ),
    },
    {
      link: "../home/meeting",
      icon: (
        <span class="material-symbols-outlined">
          calendar_month
        </span>
      ),
    },
    {
      link: "../home/projectdashboard",
      icon: (
        <span class="material-symbols-outlined">
          folder
        </span>
      ),
    },
    {
      link: "/home/quickall",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 -960 960 960"
          fill="#1E3354"
        >
          <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
        </svg>
      ),
    },
  ];

  return (
    <>
      {/* mobile */}
      <header className={`lg:hidden relative flex w-full py-2 bg-[#1E3354] drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none ${i18n.language === 'ar' ? 'text-right' : 'text-left'} ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
        <div className="h-[40px] text-white flex w-full justify-between items-center px-5">
          <div className="flex flex-col -space-y-1.5 text-center">
            <Link to="/home">
              <span class="material-symbols-outlined">
                home
              </span>
            </Link>
            <p className="text-xs">{t('home')}</p>
          </div>
          
          <div className="flex flex-col -space-y-1.5 text-center">
          <Link to="/home/quickall">
            <span class="material-symbols-outlined">
              grid_view
            </span>
          </Link>
            <p className="text-xs">{t('service')}</p>
          </div>

          <div className="flex flex-col -space-y-1.5 text-center">
          <Link to="/home/Bot">
            <span class="material-symbols-outlined">
              smart_toy
            </span>
          </Link>
          <p className="text-xs ">{t('BOT')}</p>
          </div>

          <div className="flex flex-col -space-y-1.5 text-center">
          <Link to="/home/chat">
            <span style={{ marginLeft: '7px' }} class="material-symbols-outlined">
              chat
            </span>
          </Link>
          <p className="text-xs ml-2">{t('chat')}</p>
          </div>

          <div className="flex flex-col -space-y-1.5 text-center">
          <Link to="/home/notification">
            <span class="material-symbols-outlined">
              notifications
            </span>
          </Link>
            <p className="text-xs">{t('notify')}</p>
          </div>
        </div>
      </header>
    </>
  );
}

export default Footer;
