import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CeoDashboardCircle = ({ total, values = [], sum }) => {
  const { t, i18n } = useTranslation();
  const [radius, setRadius] = useState(70);
  const [stroke, setStroke] = useState(20);
  const [data, setData] = useState({});

  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width <= 640) {
      setRadius(55);
      setStroke(10);
    } else if (width > 640 && width <= 1024) {
      setRadius(70);
      setStroke(15);
    } else {
      setRadius(85);
      setStroke(20);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const circumference = 2 * Math.PI * radius;
  const normalizedRadius = radius - stroke / 2;
  const calculateSegments = (values, total) => {
    let segments = [];
    let cumulativeLength = 0;

    values.forEach((value, index) => {
      if (value !== 0) {
        const segmentLength = (value / total) * circumference;
        const angle = (cumulativeLength / circumference) * 360 + 90;
        const textAngle = angle + ((segmentLength / circumference) * 580) / 2;

        const textX =
          radius + normalizedRadius * Math.cos((textAngle * Math.PI) / 180);
        const textY =
          radius + normalizedRadius * Math.sin((textAngle * Math.PI) / 180);

        segments.push({
          length: segmentLength,
          color: getColor(index),
          offset: cumulativeLength,
          value: value,
          textX: textX,
          textY: textY,
          angle: angle,
        });

        cumulativeLength += segmentLength;
      }
    });

    return segments;
  };

  const getColor = (index) => {
    const colors = ["#008000", "#4ADE80", "#808080"];
    return colors[index];
  };

  const indicators = [
    { color: "#008000", label: "off" },
    { color: "#4ADE80", label: "Rem" },
    { color: "#808080", label: "NC" },
  ];

  const segments = calculateSegments(values, total);

  return (
    <div className="relative w-[200px] h-[175px] min-h-[185px] md:w-[250px] md:h-[220px] mt-5 border-[3px] rounded-[10px] border-[#1E3354] p-2">
      <div className="flex items-center">
        <div className="w-[100%]">
          <p className="text-center ml-2 md:ml-0  md:mt-2 xl:-mt-2 font-semibold text-[10px] md:text-[14px]">
            Total Work Force
          </p>
        </div>
        <Link to="/home/ceodashboard">
          <div className="cursor-pointer">
            <span className="material-symbols-outlined !text-[16px]  md:mt-3 mr-1 xl:mt-0">
              more_vert
            </span>{" "}
          </div>
        </Link>
      </div>
      <div>
        <svg
          className={`inset-0 w-full h-full absolute left-2 md:left-12 lg:left-[55px] xl:left-[35px] top-8 md:top-12 xl:top-8 svg-right ${
            i18n.language === "ar" ? "-right-6" : ""
          }`}
        >
          {segments.map((segment, index) => (
            <g key={index}>
              <circle
                cx={radius}
                cy={radius}
                r={normalizedRadius}
                stroke={segment.color}
                strokeWidth={stroke}
                fill="none"
                strokeDasharray={`${segment.length} ${circumference}`}
                strokeDashoffset={-segment.offset}
                transform={`rotate(90 ${radius} ${radius})`}
              />
              <text
                x={segment.textX}
                y={segment.textY}
                textAnchor="middle"
                className="text-[5px] md:text-[8px] font-semibold"
                fill="#ffffff"
              >
                {segment.value}
              </text>
            </g>
          ))}
        </svg>

        <div className="absolute right-0 md:right-3 custom-right w-[20px] top-[40px] md:top-[60px]">
          {indicators.map((indicator, index) => (
            <div key={index} className="flex flex-col items-center mb-[5px]">
              <p
                className="rounded-full"
                style={{
                  backgroundColor: indicator.color,
                  height: "10px",
                  width: "10px",
                }}
              ></p>
              <p className="text-[10px]">{indicator.label}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="absolute top-[73px] md:top-[100px] lg:top-[95px] text-[9px] flex flex-col text-center items-center content-right md:left-[80px] lg:left-[72px] xl:left-[63px]">
        <div className="md:text-[16px] font-semibold">
          {sum}/{total}
        </div>
        <div className="lg:text-[12.5px] xl:text-[14px] font-semibold">Attendance Status</div>
      </div>
    </div>
  );
};

export default CeoDashboardCircle;
