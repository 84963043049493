import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ForgetPassword = () => {
    const { t } = useTranslation(); 
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const baseUrl = process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_BASE_URL_DEV
        : process.env.REACT_APP_BASE_URL_PROD;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${baseUrl}/resetPassword?email=${email}`, {
                method: 'GET',
                           });

            if (response.status === 200) {
                const data = await response.json();
                setMessage(data.message);
            } else {
                const errorData = await response.json();
                setError(errorData.message );
            }
        } catch (err) {
            setError('Failed to connect to the server. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="bg-[#004b8d] w-full h-screen flex flex-col justify-center items-center">
                <div className="flex w-full items-center justify-center">
                    <img
                        className="h-[100px] w-[100px]"
                        src="./assets/Mandoobee_Logo.png"
                        alt="Mandoobee Logo"
                    />
                    <div className="px-3">
                        <p className="text-[32px] font-semibold text-white">Mandoobee</p>
                        <p className="text-[15px] lg:text-[16px] font-semibold text-[#ffffff]">
                            Smart Office Application
                        </p>
                    </div>
                </div>
                <div className="flex w-full items-center justify-center px-5 lg:px-10 mt-10">
                    <form className="flex flex-col w-full items-center justify-center" onSubmit={handleSubmit}>
                        <input
                            className="border rounded-lg w-full md:w-[400px] xl:w-[500px] py-2 px-3 text-gray-700 mb-3"
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <button
                            type="submit"
                            className="bg-white mt-4 font-serif font-bold hover:bg-[#5c8ab9] hover:text-[#fff] rounded-lg w-full md:w-[400px] xl:w-[500px] py-2 px-3 text-gray-800 mb-3 "
                        >
                           Submit
                        </button>
                    </form>
                </div>
                
            </div>
        </>
    );
};

export default ForgetPassword;
