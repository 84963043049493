import "./App.css";
import { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SignIn from "./pages/Authentication/SignIn";
import routes from "./routes";
import DefaultLayout from "./layout/DefaultLayout";
import Loader from "./common/Loader";
import Dashboard from "./pages/Dashboard/Dashboard";
import SignUp from "./pages/Authentication/SignUp";
import { ThemeProvider } from "./components/Theme/Themecontext";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/translate/i18";
import Forgetpassword from "./pages/Authentication/forgetpassword";
// import { SocketProvider } from "./components/socketconnection/socket";

function App() {
  const [loading, setLoading] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    setIsUserLoggedIn(token !== null);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <ThemeProvider>
      <I18nextProvider i18n={i18n}>
        
        {loading ? (
        <Loader />
      ) : (
        <Router>
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="/password" element={<SignUp />} />
            <Route path="/forgetpassword" element={<Forgetpassword/>} />


            {isUserLoggedIn ? (
              <Route element={<DefaultLayout />}>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Dashboard />} />

                {routes.map(({ path, component: Component }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Suspense fallback={<Loader />}>
                        <Component />
                      </Suspense>
                    }
                  ></Route>
                ))}
              </Route>
            ) : (
              <Route path="/*" element={<Navigate to="/login" replace />} />

            )}
          </Routes>
        </Router>
      )}
        
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
